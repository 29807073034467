<template>
  <div class="pages p20">
    <div class="title-btn-block">
      <div class="title-block">{{ $t("message.permission_ask") }}</div>
      <div>
        <el-button class="my-btn " type="warning" @click="take" round>
         {{ $t("message.permission_ask") }}
        </el-button>
        <router-link :to="{ name: 'my_permission' }">
          <div class="my-btn cr1 el-button ml20" >
            <i class="el-icon-d-arrow-left"></i> {{ $t("message.back") }}
          </div>
        </router-link>
      
      </div>
    </div>
    <div class="content-block">
      <div class="datetime-start bg p20 mb20">
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <el-divider content-position="left">{{ $t("message.reason") }}</el-divider>
          </el-col>
          <el-col :xs="48" :sm="16" :md="16" :lg="16" :xl="16">
            <el-divider content-position="left">{{ $t("message.left_date") }}</el-divider>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="seloction mb20">
              <el-select
                v-model="form.reason_id"
                :placeholder="$t('message.reason')"
                class="w-100"
                popper-class="my-data-khan"
              >
                <el-option
                  v-for="(reason, index) in reasons"
                  :key="'guestreason-' + index"
                  :label="reason.name"
                  :value="reason.id"
                ></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="datetime-start-time mb20">
              <el-date-picker
                class="w-100"
                v-model="form.left_date"
                type="date"
                :picker-options="dateRangeOptions1"
                :placeholder="$t('message.day')"
                popper-class="my-data-khan"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="datetime-start-time mb20">
              <el-time-select
                class="w-100"
                v-model="form.left_time"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
                :placeholder="$t('message.hour')"
                popper-class="my-data-khan"
              >
              </el-time-select>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="datetime-end bg p20 mb20">
        <el-divider content-position="left">{{ $t("message.come_date") }}</el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="seloction mb20">
              <el-select
                v-model="form.duration_assent"
                :placeholder="$t('message.come_date')"
                class="w-100"
                popper-class="my-data-khan"
                @change="date(form.duration_assent)"
              >
                <!-- <el-option label="  Время" value="shanghai1"></el-option> -->
                <el-option label="1 soat" value="1"></el-option>
                <el-option label="2 soat" value="2"></el-option>
                <el-option label="Boshqa kun" value="date"></el-option>
              </el-select>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="datetime-start-time mb20">
              <el-date-picker
                class="w-100"
                v-model="form.coming_date"
                type="date"
                :placeholder="$t('message.day') "
                :picker-options="dateRangeOptions1"
                popper-class="my-data-khan"
                :disabled="disable_date"
              >
              </el-date-picker>
            </div>
          </el-col>

          <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
            <div class="datetime-start-time mb20">
              <el-time-select
                :disabled="disable_time"
                class="w-100"
                v-model="form.coming_time"
                :picker-options="{
                  start: '08:30',
                  step: '00:15',
                  end: '18:30',
                }"
                :placeholder="$t('message.hour')"
                popper-class="my-data-khan"
              >
              </el-time-select>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="comment bg p20 mb20">
        <el-divider content-position="left"> {{ $t("message.comment") }} </el-divider>
        <el-row :gutter="20">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
            <el-input type="textarea" v-model="form.comment"></el-input>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { getItem } from "@/utils/storage";
import moment from 'moment';
import { mapGetters, mapActions } from "vuex";
export default {
  props: ["id"],
  data() {
    return {
      region: "",
      value1: "",
      value: "",
      staffId:null,
      form: {},
      disable_date: true,
      disable_time: true,
      dateRangeOptions1: {
        firstDayOfWeek: 1,  
      },
    };
  },
  computed: {
    ...mapGetters({
      list: "assent/list",
      reasons: "reason/list",
    }),
  },
  mounted() {
    this.reasonsAction();
    this.staffId = getItem("userId");
  },
  methods: {
    ...mapActions({
      reasonsAction: "reason/index",
      updateList: "assent/store",
    }),
    date(val) {
      if (val == "date") {
        (this.disable_date = false), (this.disable_time = false);
      }
    },
    take() {
      this.form.staff_id = getItem("userId");
      this.form.left_date = moment(this.form.left_date).format('YYYY-MM-DD');
      this.form.coming_date = moment(this.form.coming_date).format('YYYY-MM-DD');       
      this.updateList(this.form)
        .then((res) => {
          if (res.status == 201) {
            this.$alert(res);
            this.$message({
              showClose: true,
              type: 'warning',
              duration:10000,
              dangerouslyUseHTMLString: true,
              message: "<b>Sizning IZN so'rovingiz 24 soat ichida ko'rib chiqilari!</b>"
            });
            let route = this.$router.push({
              name: "my_permission",
            });
            window.open(route.href, "_self");
          } else {
            this.$alert(res);
          }
        })
        .catch((err) => {
          this.$alert(err);
        });
    },
  },
};
</script>
